import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce, get, throttle } from 'lodash-es';
import {
  Link,
  useHistory,
  // useLocation
} from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  Form,
  Input,
  AutoComplete,
  Menu,
  Dropdown,
  Button,
} from 'antd';
import {
  SearchOutlined,
  // ShoppingCartOutlined
} from '@ant-design/icons';
import Logo from 'Static/Logo-white.svg';
import WhatsappFilledWhite from 'Static/Icons/whatsapp-filled-white.svg';
import { COSTUMER_SERVICE_WHATSAPP } from 'Configurations/Constants/config';
import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';

import Container from 'Components/Globals/Container';
import Typography from 'Components/Globals/Typography';
// import CartPopover from 'Components/CartPopover';

// import useCartNew from 'Hooks/useCartNew';
import useQueryParams from 'Hooks/useQueryParams';
import useProductSearch from 'Hooks/useProductSearch';
import useTracker from 'Hooks/useTracker';
import useAuth from 'Hooks/useAuth';
import { useQueryGetProductsByName } from 'Hooks/Api/product';

import { COLOR } from 'Configurations/Constants/styles';
import {
  StyledNavbarContainer,
  // StyledBadge,
  StyledProfileMenu,
  StyledProfileName,
  StyledProfileButton,
  StyledAvatar,
  StyledVerticalDivider,
} from './Navbar.style';

/**
 * Main Component
 */
const Navbar = ({
  isLocationPickerVisible,
  isSearchFieldVisible,
  isNavbarWhatsappButtonVisible,
  // eslint-disable-next-line no-unused-vars
  isNavbarCartButtonVisible,
  isLoginButtonVisible,
}) => {
  const [isProfileDropdownOpened, setIsProfileDropdowOpened] = useState();
  const [form] = Form.useForm();

  const { isLogin, authData, logout } = useAuth();

  const loggedInName = authData?.name || 'Profile';
  const loggedInNameFirstLetter = loggedInName.charAt(0).toUpperCase();

  // const { totalProductsInCart } = useCartNew();

  // const location = useLocation();

  const history = useHistory();
  const queryParams = useQueryParams();
  const { searchQuery, setSearchQuery } = useProductSearch();
  const { products: productsResult, getProductsByName } =
    useQueryGetProductsByName({
      searchQuery,
    });
  const { trackWhatsappButtonClick } = useTracker();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  // const [showCartPopover, setShowCartPopover] = useState(false);

  const throttledGetProductsByName = useMemo(
    () => throttle(param => getProductsByName(param), 800),
    []
  );
  const debouncedGetProductsByName = useMemo(
    () => debounce(param => throttledGetProductsByName(param), 800),
    []
  );

  const openWhatsapp = () => {
    contactWhatsapp(COSTUMER_SERVICE_WHATSAPP);

    trackWhatsappButtonClick({
      location: 'Whatsapp Icon - Navbar',
    });
  };

  // const goToCartPage = () => {
  //   if (location.pathname !== '/cart') {
  //     history.push('/cart');
  //   }
  // };

  const menu = (
    <Menu
      items={[
        {
          label: <Link to="/orders">Daftar Pesanan</Link>,
        },
        {
          type: 'divider',
        },
        {
          label: <Link to="/address">Daftar Alamat</Link>,
        },
        {
          type: 'divider',
        },
        {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          label: <span onClick={() => logout()}>Keluar</span>,
        },
      ]}
    />
  );

  /**
   * Invoked when the user clicks one item from the autocomplete
   * Redirects user to the product details page
   */
  const onAutoCompleteItemSelect = (_, option) => {
    history.push(`/products/${option.id}`);
  };

  /**
   * @effect
   * Do search products by name if the user has typed some string in the search field
   */
  useEffect(() => {
    if (!searchQuery) {
      setAutoCompleteOptions([]);
    }
    debouncedGetProductsByName({ searchQuery });
    form.setFieldsValue({ 'search-query': searchQuery });
  }, [searchQuery]);

  /**
   * @effect
   * Compose the productsResult array to AutoComplete `options` format
   */
  useEffect(() => {
    if (productsResult.length) {
      const result = productsResult.map(x => ({ value: x.name, id: x.id }));
      setAutoCompleteOptions([...result]);
    }
  }, [productsResult]);

  /**
   * @effect
   * cleanup debounced and throttled functions when unmounts
   */
  useEffect(() => {
    throttledGetProductsByName.cancel();
    debouncedGetProductsByName.cancel();
  }, []);

  /**
   * Invoked when the user press enter on the search field, when the autocomplete result is not showing
   * @param {object} values
   */
  const onFormFinish = values => {
    const q = get(values, 'search-query');
    if (q) {
      /**
       * Searching by navbar should clear all filters
       */
      queryParams.set('q', q);
      queryParams.delete('category');
      queryParams.delete('1');
      queryParams.delete('2');
      queryParams.delete('3');
      queryParams.delete('page');
      history.push(`/products?${queryParams}`);
    }
  };

  const onSearchFieldEnter = (value = '') => {
    if (value) {
      queryParams.set('q', value);
      history.push(`/products${queryParams ? `?${queryParams}` : ''}`);
    }
  };

  return (
    <StyledNavbarContainer>
      <Container style={{ height: '100%' }}>
        <Row
          style={{ width: '100%', marginRight: 0, marginLeft: 0 }}
          justify="space-between"
          gutter={32}
        >
          {/* Logo */}
          <Col flex="120px">
            <Link to="/">
              <Image
                preview={false}
                src={Logo}
                alt="Materee.id"
                width={120}
                style={{ marginBottom: 8, marginTop: 8, cursor: 'pointer' }}
              />
            </Link>
          </Col>

          {/* Location */}
          {isLocationPickerVisible && (
            <Col
              flex="96px"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography.Caption12 style={{ color: '#FFF4EE' }}>
                Wilayah
              </Typography.Caption12>
              <Typography.Body14
                style={{ fontWeight: 'bold', color: '#FFF4EE' }}
              >
                Jabodetabek
              </Typography.Body14>
            </Col>
          )}

          {/* Search Field and Autocomplete */}
          {isSearchFieldVisible && (
            <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
              <AutoComplete
                options={autoCompleteOptions}
                onSelect={onAutoCompleteItemSelect}
                style={{ width: '100%' }}
              >
                <Form onFinish={onFormFinish} form={form}>
                  <Form.Item name="search-query" style={{ marginBottom: 0 }}>
                    <Input
                      placeholder="Cari nama produk"
                      suffix={
                        <SearchOutlined
                          style={{ fontSize: 16, color: '#d9d9d9' }}
                        />
                      }
                      onChange={evt => setSearchQuery(evt.target.value || '')}
                      onPressEnter={evt =>
                        onSearchFieldEnter(evt.target.value || '')
                      }
                    />
                  </Form.Item>
                </Form>
              </AutoComplete>
            </Col>
          )}

          {/* Whatsapp */}
          {isNavbarWhatsappButtonVisible && (
            <Col flex="28px" style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{ width: 24, cursor: 'pointer' }}
                role="button"
                tabIndex="0"
                onKeyDown={openWhatsapp}
                onClick={openWhatsapp}
              >
                <Image src={WhatsappFilledWhite} preview={false} />
              </span>
            </Col>
          )}

          {/** Cart Icon */}
          {/* {isNavbarCartButtonVisible && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: 48,
              }}
              onClick={goToCartPage}
              onKeyDown={goToCartPage}
              tabIndex="0"
              role="button"
              onMouseEnter={() => setShowCartPopover(true)}
            >
              <StyledBadge count={totalProductsInCart} showZero>
                <ShoppingCartOutlined
                  style={{ fontSize: 24, color: 'white' }}
                />
              </StyledBadge>

              {showCartPopover && (
                <CartPopover
                  onMouseEnter={() => setShowCartPopover(true)}
                  onMouseLeave={() => setShowCartPopover(false)}
                />
              )}
            </div>
          )} */}

          {/** Login Button / Logged in state button */}
          {isLoginButtonVisible && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StyledVerticalDivider />

              {isLogin ? (
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  onOpenChange={isOpen => setIsProfileDropdowOpened(isOpen)}
                >
                  <StyledProfileMenu>
                    <StyledProfileButton
                      type="text"
                      isOpen={isProfileDropdownOpened}
                    >
                      <StyledAvatar variant="white" size="default">
                        <Typography.Body14 fontWeight="bold" htmlTag="span">
                          {loggedInNameFirstLetter}
                        </Typography.Body14>
                      </StyledAvatar>
                      <StyledProfileName fontWeight="bold" color={COLOR.WHITE}>
                        {loggedInName}
                      </StyledProfileName>
                    </StyledProfileButton>
                  </StyledProfileMenu>
                </Dropdown>
              ) : (
                <Button onClick={() => history.push('/login')}>Masuk</Button>
              )}
            </div>
          )}
        </Row>
      </Container>
    </StyledNavbarContainer>
  );
};

Navbar.propTypes = {
  isLocationPickerVisible: PropTypes.bool,
  isSearchFieldVisible: PropTypes.bool,
  isNavbarWhatsappButtonVisible: PropTypes.bool,
  isNavbarCartButtonVisible: PropTypes.bool,
  isLoginButtonVisible: PropTypes.bool,
};

Navbar.defaultProps = {
  isLocationPickerVisible: true,
  isSearchFieldVisible: true,
  isNavbarWhatsappButtonVisible: false,
  isNavbarCartButtonVisible: false,
  isLoginButtonVisible: false,
};

export default Navbar;
